import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectFavoriteBuildings } from '../store/slices/AppSlice';
import BuildingListItem from './BuildingListItem';
import { Grid } from '@material-ui/core';
import { Artifact } from '@primus-modules/primus-app-core';

const FavoritesList: FC = () => {
  const favoriteBuildings: Array<Artifact> = useSelector(selectFavoriteBuildings);
  return (
    <Grid container alignItems={'center'} spacing={3}>
      {favoriteBuildings.map(building => (
        <BuildingListItem key={building.artifact_id} building={building} />
      ))}
    </Grid>
  );
};

export default FavoritesList;
