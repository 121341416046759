import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const LastUpdatedList: FC = () => {
  const { t } = useTranslation('common');

  return <h2>{t('lastUpdatedList.header')}</h2>;
};

export default LastUpdatedList;
