import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../Store';
import * as pkg from '../../../package.json';
import { PersistentStore } from '../PersistentStore';

export interface AppState {
  /**
   * The current version of the application
   */
  appVersion: string;
  /**
   * List over 5 latest searches
   */
  searchHistory: Array<string>;

  /**
   * List over favoritebuildings
   */
  favoriteBuildings: Array<any>;
}

const name = 'app';

export const appSlice = createSlice({
  name,
  initialState: {
    appVersion: pkg.version,
    searchHistory: PersistentStore.getS<Array<string>, AppState>(name, 'searchHistory', []),
    favoriteBuildings: PersistentStore.getS<Array<any>, AppState>(name, 'favoriteBuildings', []),
  } as AppState,
  reducers: {
    addFavoriteBuilding: (state, action) => {
      state.favoriteBuildings.unshift(action.payload);
      PersistentStore.setS<AppState>(name, 'favoriteBuildings', state.favoriteBuildings);
    },
    removeFavoriteBuilding: (state, action) => {
      state.favoriteBuildings = state.favoriteBuildings.filter(
        buildingToRemove => buildingToRemove.artifact_id !== action.payload.artifact_id
      );
      PersistentStore.setS<AppState>(name, 'favoriteBuildings', state.favoriteBuildings);
    },
    addSearch: (state, action) => {
      if (!action.payload || action.payload === '*:*') {
        return;
      }

      state.searchHistory.unshift(action.payload);
      state.searchHistory = state.searchHistory.slice(0, 5);
      PersistentStore.setS<AppState>(name, 'searchHistory', state.searchHistory);
    },
  },
});

export const selectAppVersion = (state: RootState): string => 'v' + state.app.appVersion;
export const selectSearchHistory = (state: RootState): Array<string> => state.app.searchHistory;
export const selectFavoriteBuildings = (state: RootState): Array<any> => state.app.favoriteBuildings;

export const { addSearch } = appSlice.actions;
export const { addFavoriteBuilding } = appSlice.actions;
export const { removeFavoriteBuilding } = appSlice.actions;

export const appReducer = appSlice.reducer;

export default appReducer;
