import React, { Suspense, useEffect, useMemo } from 'react';
import {
  Language,
  PrimusApiProvider,
  PrimusEkulturLogin,
  PrimusEkulturLoginLabels,
  useOfflineEvent,
  SystemMessageService,
} from '@primus-modules/primus-app-core';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { getFdvApi } from './services/FdvApi';
import MainLayout from './framework/MainLayout';
import HomeContent from './views/HomeContent';
import { useAppDispatch } from './store/Store';
import { useSelector } from 'react-redux';
import { selectDefaultTheme } from './store/slices/ThemeSlice';
import { ThemeProvider } from '@material-ui/core';
import { setUser } from './store/slices/UserSlice';
import { warning } from './store/slices/SystemMessageSlice';

const primusApi = getFdvApi();

function App() {
  const dispatch = useAppDispatch();
  const theme = useSelector(selectDefaultTheme);
  const { t, i18n } = useTranslation('common');
  const isOffline = useOfflineEvent();

  const loginLabels = useMemo<PrimusEkulturLoginLabels>(
    () => ({
      appTitle: t('app.title'),
      contactSupport: t('login.contactSupport'),
      appOffline: t('login.appOffline'),
      completeLoginInOpenWindow: t('login.completeLoginInOpenWindow'),
      loginFailed: t('login.loginFailed'),
      selectServerInputLabel: t('login.selectServerInputLabel'),
      selectServerButtonLabel: t('login.selectServerButtonLabel'),
      loggingIn: t('login.loggingIn'),
      tryAgainButtonLabel: t('login.tryAgainButtonLabel'),
      loggingOut: t('login.loggingOut'),
      controllingCredentials: t('login.controllingCredentials'),
      startLoginButtonText: t('login.startLoginButtonText'),
      logoutFailed: t('login.logoutFailed'),
      loginTimedOut: t('login.loginTimedOut'),
      abortLoginButtonLabel: t('login.abortLoginButtonLabel'),
    }),
    [t]
  );

  const handleLogin = async () => {
    await dispatch(setUser());
  };

  const handleLogout = () => {
    window.localStorage.clear();
  };

  const handleLanguageChanged = (language: Language) => {
    const locale = String(language).toLowerCase() || window.localStorage.getItem('fdv.locale') || 'no';
    i18n.changeLanguage(locale).then(() => window.localStorage.setItem('fdv.locale', locale));
  };

  useEffect(() => {
    if (isOffline) {
      dispatch(warning(t('app.offline')));
    }
  }, [isOffline, t, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <SystemMessageService transformMessage={msg => t(msg)}>
        <Suspense fallback={<p>TODO: Loading...</p>}>
          <PrimusApiProvider apiInstance={primusApi}>
            <BrowserRouter>
              <PrimusEkulturLogin
                onLogin={handleLogin}
                onLogout={handleLogout}
                onLanguageChanged={handleLanguageChanged}
                labels={loginLabels}>
                <Switch>
                  <Route path="/home">
                    <MainLayout>
                      <HomeContent />
                    </MainLayout>
                  </Route>
                  <Route path="*">
                    <Redirect to="/home" />
                  </Route>
                </Switch>
              </PrimusEkulturLogin>
            </BrowserRouter>
          </PrimusApiProvider>
        </Suspense>
      </SystemMessageService>
    </ThemeProvider>
  );
}

export default App;
