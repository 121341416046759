import React, { FC, useMemo } from 'react';
import { Grid, IconButton, Paper, styled, Typography } from '@material-ui/core';
import { getFdvApi } from '../services/FdvApi';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { useSelector } from 'react-redux';

import { addFavoriteBuilding, removeFavoriteBuilding, selectFavoriteBuildings } from '../store/slices/AppSlice';
import { useAppDispatch } from '../store/Store';
import NotFoundImage from '../asset/images/no-image-found.png';

interface BuildingListItemProps {
  building: any;
}

const BuildingImage = styled('img')({
  width: 100,
  height: 100,
  objectFit: 'cover',
  objectPosition: 50,
  marginLeft: 10,
  transition: 'all 200ms ease-in-out',
});

const DateGridItem = styled(Grid)({
  marginRight: 10,
});

const primusApi = getFdvApi();
const BuildingListItem: FC<BuildingListItemProps> = ({ building }) => {
  const dispatch = useAppDispatch();
  const favoriteBuildings: Array<any> = useSelector(selectFavoriteBuildings);
  const markedAsFavorite = useMemo(
    () => favoriteBuildings.some(favoriteBuilding => favoriteBuilding.artifact_id === building.artifact_id),
    [favoriteBuildings, building]
  );

  const toggleBuildingAsFav = () => {
    markedAsFavorite ? dispatch(removeFavoriteBuilding(building)) : dispatch(addFavoriteBuilding(building));
  };

  const getMeasureClassBorderColor = (measureClass: string): string => {
    const measure = measureClass.trim().substr(0, 3);
    if (measure === 'TK0') {
      return '#cccccc';
    }
    if (measure === 'TK1') {
      return '#ffcb4f';
    }
    if (measure === 'TK2') {
      return '#ff5912';
    }
    if (measure === 'TK3') {
      return '#cc0033';
    }
    return '#ffffff';
  };

  return (
    <Grid item xs={12} key={building.artifact_id}>
      <Paper>
        <Grid container spacing={2}>
          <Grid item>
            {building.thumbnail_id && (
              <BuildingImage
                style={{ width: 100, height: 100, objectFit: 'cover', objectPosition: '50%' }}
                alt="complex"
                src={`${primusApi.getFullApiUrl()}/image/${building.thumbnail_id}`}
              />
            )}

            {!building.thumbnail_id && (
              <BuildingImage
                style={{ width: 100, height: 100, objectFit: 'cover', objectPosition: '50%' }}
                alt="complex"
                src={NotFoundImage}
              />
            )}
          </Grid>
          <Grid item xs={4} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography variant="subtitle1" component="span">
                  {building.artifact_name}
                  <IconButton color="inherit" size="small" onClick={toggleBuildingAsFav}>
                    {markedAsFavorite ? <StarIcon /> : <StarBorderIcon />}
                  </IconButton>
                </Typography>
              </Grid>
              <Grid item xs>
                {building.collection_id_value && (
                  <Typography variant="body2" gutterBottom component="span">
                    Kolleksjon {building.collection_id_value}
                  </Typography>
                )}
                {building.identifier && (
                  <Typography variant="body2" color="textSecondary" component="span">
                    {building.identifier}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <DateGridItem item xs={2} container>
            <Typography variant="body2" color={'textSecondary'} component="span">
              {/*todo:make locale dynamic set to current language*/}
              {new Date(building.updated_at.dd_date).toLocaleDateString()}
            </Typography>
          </DateGridItem>
          <Grid item style={{ marginLeft: 2 }}>
            {building.measure_class_type && (
              <Typography
                component="span"
                style={{ background: getMeasureClassBorderColor(building.measure_class_type) }}>
                {building.measure_class_type.substr(0, 3)}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default BuildingListItem;
