import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFdvApi } from '../services/FdvApi';
import { Button, Grid, Slide, styled } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import FilterItem, { FilterValue } from './FilterListItem';
import { createCancellationPromise } from '@primus-modules/primus-app-core';

export interface FilterValues {
  [filterName: string]: FilterValue[];
}

interface SearchFiltersProps {
  typeOfArtifact: any;
  handleFilterToggle: (option: string, filterGroup: string) => void;
}

const ShowHideFiltersButton = styled(Button)({
  width: 200,
  margin: '0 auto',
  display: 'block',
  paddingBottom: '0',
  transition: 'all 200ms ease-in-out',
  background: 'transparent;',
});

const GridItem = styled(Grid)({
  margin: 10,
});

const api = getFdvApi();

const FilterList: FC<SearchFiltersProps> = ({ typeOfArtifact, handleFilterToggle }) => {
  const { t } = useTranslation('common');
  const [filterValues, setFilterValues] = useState<FilterValues>();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    const { promise, cancel } = createCancellationPromise(api.fetchFilterValues());
    promise
      .then(data => setFilterValues(data as FilterValues))
      .catch(err => {
        if (err !== 'Cancelled') {
          console.error(err);
        }
      });
    return cancel;
  }, []);

  const toggleSearchFilters = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <>
      {isFilterOpen && (
        <Slide in={isFilterOpen} direction="down">
          <Grid container direction="column" spacing={1}>
            <GridItem item container direction="column" spacing={1}>
              <GridItem item>
                {filterValues &&
                  Object.keys(filterValues)
                    .filter(key => typeOfArtifact.filters.includes(key))
                    .map(key => (
                      <FilterItem
                        key={key}
                        category={key}
                        filterValues={(filterValues as any)[key] as Array<FilterValue>}
                        handleFilterToggle={handleFilterToggle}
                      />
                    ))}
              </GridItem>
            </GridItem>
          </Grid>
        </Slide>
      )}
      {isFilterOpen ? (
        <ShowHideFiltersButton variant="contained" onClick={toggleSearchFilters}>
          {t('filterList.hideFiltersButton')}
          <ExpandLessIcon />
        </ShowHideFiltersButton>
      ) : (
        <ShowHideFiltersButton variant="contained" onClick={toggleSearchFilters}>
          {t('filterList.showFiltersButton')}
          <ExpandMoreIcon />
        </ShowHideFiltersButton>
      )}
    </>
  );
};

export default FilterList;
