import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import BuildingListItem from '../components/BuildingListItem';
import { Artifact } from '@primus-modules/primus-app-core';

interface BuildingListProps {
  buildings: Array<Artifact>;
}

const BuildingList: FC<BuildingListProps> = ({ buildings }) => (
  <Grid container alignItems={'center'} spacing={3}>
    {buildings.map(building => (
      <BuildingListItem key={building.artifact_id} building={building} />
    ))}
  </Grid>
);

export default BuildingList;
