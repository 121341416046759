import React, { useEffect, useState } from 'react';
import { styled } from '@material-ui/core';
import DraftsList from '../components/DraftsList';
import FavoritesList from '../components/FavoritesList';
import LastUpdatedList from '../components/LastUpdatedList';
import { getFdvApi } from '../services/FdvApi';

const HomeContent = () => {
  const [draftsObjects, setDraftsObjects] = useState<any>([]);
  const [lastUpdatedObjects, setLastUpdatedObjects] = useState<any>([]);
  const DRAFTS_STATUS = 'Under arbeid';
  const Container = styled('div')(({ theme }) => ({
    margin: theme.spacing(3, 0, 0),
  }));

  // set last updated events
  useEffect(() => {
    getFdvApi()
      .getLastUpdatedObjects()
      .then(objects => setLastUpdatedObjects(objects));
  }, []);

  // filter out draft tasks
  useEffect(() => {
    if (lastUpdatedObjects.length === 0) return;
    const draftEvents = lastUpdatedObjects.filter(
      (obj: any) => obj['status.status_type_id_value'] && obj['status.status_type_id_value'] === DRAFTS_STATUS
    );
    setDraftsObjects(draftEvents);
  }, [lastUpdatedObjects]);

  return (
    <Container>
      <FavoritesList />
      <DraftsList draftsObjects={draftsObjects} />
      <LastUpdatedList />
    </Container>
  );
};

export default HomeContent;
