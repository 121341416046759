import { createSlice, Draft } from '@reduxjs/toolkit';
import { RootState } from '../Store';
import { AlertProps } from '@primus-modules/primus-app-core';

export interface SystemMessage {
  id: number;
  severity: AlertProps['severity'];
  message: string;
}

export interface SystemMessageState {
  messages: Array<SystemMessage>;
}

const name = 'systemMessage';

const putMessage = (severity: SystemMessage['severity']) => (state: Draft<SystemMessageState>, action: any) => {
  if (!action.payload) {
    return;
  }
  state.messages.push({
    id: Date.now(),
    message: action.payload,
    severity: severity,
  } as SystemMessage);
};

const systemMessageSlice = createSlice({
  name,
  initialState: {
    messages: [],
  } as SystemMessageState,
  reducers: {
    info: putMessage('info'),
    warning: putMessage('warning'),
    error: putMessage('error'),
    success: putMessage('success'),
    completeMessage: (state, action) => {
      if (!action.payload) {
        return;
      }
      const idx = state.messages.findIndex(m => m.id === action.payload);
      if (idx >= 0) {
        state.messages.splice(idx, 1);
      }
    },
  },
});

export const selectCurrentMessage = (state: RootState) => state.systemMessage.messages[0];

export const { info, warning, error, success, completeMessage } = systemMessageSlice.actions;

const systemMessageReducer = systemMessageSlice.reducer;

export default systemMessageReducer;
