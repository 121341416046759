import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import common_en from './translations/en/common.json';
import common_no from './translations/no/common.json';
import { CssBaseline } from '@material-ui/core';
import store from './store/Store';
import { Provider } from 'react-redux';

//load translations from translations folder
i18next
  .init({
    interpolation: { escapeValue: false },
    lng: window.localStorage.getItem('fdv.locale') || 'no',
    resources: {
      en: {
        common: common_en,
      },
      no: {
        common: common_no,
      },
    },
  })
  .then();

ReactDOM.render(
  <>
    <CssBaseline />
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </Provider>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
