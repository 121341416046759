import React, { FC } from 'react';
import { AppHeaderBar, AppHeaderBarProps, PrimusAppBrand } from '@primus-modules/primus-app-core';
import { useTranslation } from 'react-i18next';
import { Menu } from '@material-ui/icons';

export type MainLayoutHeaderProps = Pick<AppHeaderBarProps, 'onSearch' | 'onAction'>;

export const MainLayoutHeader: FC<MainLayoutHeaderProps> = ({ onAction, onSearch }) => {
  const { t } = useTranslation('common');

  return (
    <AppHeaderBar
      color="secondary"
      actionButtonIcon={<Menu />}
      brandContent={<PrimusAppBrand appName={t('app.title')} />}
      position="relative"
      onAction={onAction}
      enableSearchHeader
      onSearch={onSearch}
      searchInputLabel={t('app.searchInputPlaceholder')}
    />
  );
};

export default MainLayoutHeader;
