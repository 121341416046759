import { createMuiTheme, Theme, ThemeOptions } from '@material-ui/core';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../Store';
import { DEFAULT_THEME_PRIMARY_COLOR, DEFAULT_THEME_SECONDARY_COLOR } from '../../asset/colors';

export interface ThemeState {
  defaultTheme: ThemeOptions;
}

const name = 'themes';

const themeSlice = createSlice({
  name,
  initialState: {
    defaultTheme: {
      palette: {
        type: 'light',
        primary: {
          main: DEFAULT_THEME_PRIMARY_COLOR,
        },
        secondary: {
          main: DEFAULT_THEME_SECONDARY_COLOR,
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
      },
    },
  } as ThemeState,
  reducers: {},
});

export const selectDefaultTheme = (state: RootState): Theme => createMuiTheme(state.themes.defaultTheme);

export const themeReducer = themeSlice.reducer;

export default themeReducer;
