import React, { FC, useMemo } from 'react';
import { AppFooterBar, AppFooterBarAction } from '@primus-modules/primus-app-core';
import { useTranslation } from 'react-i18next';
import { Create, Favorite, Search } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

export const MainLayoutFooter: FC = () => {
  const { t } = useTranslation('common');
  const history = useHistory();

  const footerBarActions = useMemo<Array<AppFooterBarAction>>(
    () => [
      { id: 'favourites', label: t('bottomNavigation.favourites'), icon: <Favorite /> },
      { id: 'last-updated', label: t('bottomNavigation.lastUpdated'), icon: <Search /> },
      { id: 'drafts', label: t('bottomNavigation.drafts'), icon: <Create /> },
    ],
    [t]
  );

  return <AppFooterBar actionList={footerBarActions} onAction={id => history.push('/' + id)} elevation={4} />;
};

export default MainLayoutFooter;
