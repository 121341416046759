import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Artifact } from '@primus-modules/primus-app-core';
import { useTranslation } from 'react-i18next';

export interface DraftListProps {
  draftsObjects: Array<Artifact>;
}

const DraftsList: FC<DraftListProps> = ({ draftsObjects }) => {
  const { t } = useTranslation('common');

  return (
    <Box>
      <Box>
        <h2>{t('draftList.header')}</h2>
      </Box>
      <Box>
        <Grid container>
          {draftsObjects.map((draft, idx, arr) => (
            <Box key={idx}> {draft.artifact_name}</Box>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default DraftsList;
