import React, { FC, ReactNode } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface MainLayoutSideMenuItemProps {
  label: string;
  link: string;
  icon: ReactNode;
}

export const MainLayoutSideMenuItem: FC<MainLayoutSideMenuItemProps> = ({ label, link, icon }) => {
  const { t } = useTranslation('common');
  const history = useHistory();

  return (
    <ListItem button onClick={() => history.push(link)}>
      <ListItemIcon>{icon}</ListItemIcon>

      <ListItemText>{t(label)}</ListItemText>
    </ListItem>
  );
};

export default MainLayoutSideMenuItem;
