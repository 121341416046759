import React, { FC, useEffect, useState } from 'react';
import { AppLayout, Artifact, createCancellationPromise } from '@primus-modules/primus-app-core';
import { getFdvApi } from '../services/FdvApi';
import SearchResultView from '../views/SearchResultView';
import { useAppDispatch } from '../store/Store';
import { addSearch } from '../store/slices/AppSlice';
import MainLayoutHeader from './MainLayoutHeader';
import MainLayoutSideMenu from './MainLayoutSideMenu';
import MainLayoutFooter from './MainLayoutFooter';

const api = getFdvApi();

const MainLayout: FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<Array<Artifact>>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    const { promise, cancel } = createCancellationPromise(api.fetchSearchedObjects(searchQuery));
    promise
      .then(result => {
        dispatch(addSearch(searchQuery));
        setSearchResult(result);
      })
      .catch(err => {
        if (err !== 'Cancelled') {
          console.error(err);
          setSearchResult([]);
        }
      });

    return cancel;
  }, [searchQuery, dispatch]);

  return (
    <AppLayout
      menuPosition="left"
      menuOpen={menuOpen}
      onMenuClose={() => setMenuOpen(false)}
      headerContent={<MainLayoutHeader onSearch={setSearchQuery} onAction={() => setMenuOpen(true)} />}
      menuContent={<MainLayoutSideMenu />}
      footerContent={<MainLayoutFooter />}>
      {!searchResult && children}
      {searchResult && <SearchResultView searchResult={searchResult} />}
    </AppLayout>
  );
};

export default MainLayout;
