/**
 * Methods to easily persist data
 */
export abstract class PersistentStore {
  public static getS<T, S>(sliceName: string, key: keyof S, defaultValue: T | null = null, parseJson = true): T {
    return PersistentStore.get<T>(`${sliceName}/${key}`, defaultValue, parseJson);
  }

  public static setS<S>(sliceName: string, key: keyof S, value: any, parseJson = true): void {
    PersistentStore.set(`${sliceName}/${key}`, value, parseJson);
  }

  public static getBoolS<S>(sliceName: string, key: keyof S, defaultValue: boolean | null = null): boolean {
    return PersistentStore.getBool(`${sliceName}/${key}`, defaultValue);
  }

  public static setBoolS<S>(sliceName: string, key: keyof S, value: any): void {
    PersistentStore.setBool(`${sliceName}/${key}`, value);
  }

  public static get<T = string>(key: string, defaultValue: T | null = null, parseJson = true): T {
    const data: string | null = window.localStorage.getItem(key);
    const parsed: T = parseJson && data !== null && data !== undefined ? JSON.parse(data) : data;
    return (parsed || defaultValue) as T;
  }

  public static getBool(key: string, defaultValue: boolean | null = null): boolean {
    const dv: string | null = defaultValue === null ? null : defaultValue ? 'true' : 'false';
    return PersistentStore.get(key, dv, false) === 'true';
  }

  public static set(key: string, value: any, parseJson = true): void {
    if (!key) {
      return;
    }
    window.localStorage.setItem(key, parseJson ? JSON.stringify(value) : value);
  }

  public static setBool(key: string, value: boolean | any): void {
    PersistentStore.set(key, !!value, false);
  }

  public static clearData(): void {
    window.localStorage.clear();
  }
}
