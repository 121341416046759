import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Box, Container, Tab, Tabs, Typography } from '@material-ui/core';
import { Artifact } from '@primus-modules/primus-app-core';
import BuildingList from '../components/BuildingList';
import FilterList from '../components/FilterList';
import { filterObjects } from '../helpers/filterObjects';

interface SearchResultViewProps {
  searchResult: Array<Artifact>;
}

export type EnabledFiltersType = {
  collections: {
    filters: string[];
  };
  registrationLevel: {
    filters: string[];
  };
  conditionClass: {
    filters: string[];
  };
  purpose: {
    filters: string[];
  };
  priority: {
    filters: string[];
  };
  status: {
    filters: string[];
  };
  type: {
    filters: string[];
  };
  hasMarkings: {
    filters: string[];
  };
};

const SearchResultView: FC<SearchResultViewProps> = ({ searchResult }) => {
  const [searchViewOption, setSearchViewOption] = React.useState(0);
  const searchViewOptions = [
    { name: 'Bygninger', filters: ['collections', 'registrationLevel', 'conditionClass'] },
    { name: 'Avvik', filters: ['priority', 'status', 'hasMarkings', 'createdBy', 'type'] },
    { name: 'Tilstandsvurdering', filters: ['purpose', 'conditionClass', 'hasMarkings', 'createdBy'] },
    { name: 'Bygningsarbeid', filters: ['bygningsdel', 'hasMarkings', 'createdBy'] },
    { name: 'Oppgaver', filters: ['collections', 'hasMarkings', 'createdBy', 'status', 'priority', 'ansvarlig'] },
  ];
  const [enabledFilters, setEnabledFilters] = useState<EnabledFiltersType>({
    collections: {
      filters: [] as string[],
    },
    registrationLevel: {
      filters: [] as string[],
    },
    conditionClass: {
      filters: [] as string[],
    },
    purpose: {
      filters: [] as string[],
    },
    priority: {
      filters: [] as string[],
    },
    status: {
      filters: [] as string[],
    },
    type: {
      filters: [] as string[],
    },
    hasMarkings: {
      filters: [] as string[],
    },
  });

  const handleSearchViewOptionChange = (event: ChangeEvent<unknown>, newValue: number) => {
    setSearchViewOption(newValue);
  };

  const buildings = useMemo(() => searchResult.filter(item => item.object_type === 'building'), [searchResult]);
  const observations = useMemo(() => searchResult.filter(item => item.object_type === 'BuildingObservationEvent'), [
    searchResult,
  ]);
  const conditionAssessments = useMemo(
    () => searchResult.filter(item => item.object_type === 'BuildingConditionAssessmentEvent'),
    [searchResult]
  );
  const treatments = useMemo(() => searchResult.filter(item => item.object_type === 'BuildingTreatmentEvent'), [
    searchResult,
  ]);
  const assignments = useMemo(() => searchResult.filter(item => item.object_type === 'task'), [searchResult]);

  useEffect(() => {
    filterObjects(searchResult, enabledFilters);
  }, [enabledFilters, searchResult]);

  const objectsLength = [
    buildings.length,
    observations.length,
    conditionAssessments.length,
    treatments.length,
    assignments.length,
  ];

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  const handleToggleEnableFilter = (option: string, filterGroup: string) => {
    // set as selected if not already selected
    if (!(enabledFilters as any)[filterGroup].filters.includes(option)) {
      setEnabledFilters({
        ...enabledFilters,
        [filterGroup]: {
          ...(enabledFilters as any)[filterGroup],
          filters: [option],
        },
      });
      return;
    }

    // check if option is selected to unselect it
    if ((enabledFilters as any)[filterGroup].filter.includes(option)) {
      const newEnabledFilters = {
        ...enabledFilters,
        [filterGroup]: {
          ...(enabledFilters as any)[filterGroup],
          filters: (enabledFilters as any)[filterGroup].filters.filter((opt: string) => opt !== option),
        },
      };
      setEnabledFilters(newEnabledFilters);
      return;
    }

    setEnabledFilters({
      ...enabledFilters,
      [filterGroup]: {
        ...(enabledFilters as any)[filterGroup],
        filters: [...(enabledFilters as any)[filterGroup].filters, option],
      },
    });
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (
          <Box p={3}>
            <Typography component="span">{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  return (
    <>
      <Tabs value={searchViewOption} onChange={handleSearchViewOptionChange} variant="scrollable" scrollButtons="on">
        {searchViewOptions.map((option, idx) => (
          <Tab
            key={idx}
            label={
              <>
                {option.name} {objectsLength[idx]}
              </>
            }
          />
        ))}
      </Tabs>
      <FilterList typeOfArtifact={searchViewOptions[searchViewOption]} handleFilterToggle={handleToggleEnableFilter} />
      <Container>
        <TabPanel value={searchViewOption} index={0}>
          {buildings.length > 0 && <BuildingList buildings={buildings} />}
        </TabPanel>
        <TabPanel value={searchViewOption} index={1}>
          {observations.length > 0 && <h1>Observasjonsliste</h1>}
        </TabPanel>
        <TabPanel value={searchViewOption} index={2}>
          {conditionAssessments.length > 0 && <h1>bygnignsliste</h1>}
        </TabPanel>
        <TabPanel value={searchViewOption} index={3}>
          {treatments.length > 0 && <h1>behandlingsliste</h1>}
        </TabPanel>
        <TabPanel value={searchViewOption} index={4}>
          {assignments.length > 0 && <h1>oppgaveliste</h1>}
        </TabPanel>
      </Container>
    </>
  );
};

export default SearchResultView;
