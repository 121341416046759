import { configureStore } from '@reduxjs/toolkit';
import appReducer, { AppState } from './slices/AppSlice';
import userReducer, { UserState } from './slices/UserSlice';

import { useDispatch } from 'react-redux';
import themeReducer, { ThemeState } from './slices/ThemeSlice';
import systemMessageReducer, { SystemMessageState } from './slices/SystemMessageSlice';

export interface RootState {
  app: AppState;
  user: UserState;
  themes: ThemeState;
  systemMessage: SystemMessageState;
}

const store = configureStore<RootState>({
  reducer: {
    app: appReducer,
    user: userReducer,
    themes: themeReducer,
    systemMessage: systemMessageReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const getState = (): RootState => store.getState();

export default store;
