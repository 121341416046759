import React, { FC, useEffect, useState } from 'react';
import { FlexContainer, ProfileMenuSection } from '@primus-modules/primus-app-core';
import { Divider, List } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectUser, selectUserConfig } from '../store/slices/UserSlice';
import MainLayoutSideMenuItem, { MainLayoutSideMenuItemProps } from './MainLayoutSideMenuItem';
import { selectSearchHistory } from '../store/slices/AppSlice';
import { MeetingRoom, Search } from '@material-ui/icons';

export const MainLayoutSideMenu: FC = () => {
  const userConfig = useSelector(selectUserConfig);
  const user = useSelector(selectUser);

  const searchHistory: Array<string> = useSelector(selectSearchHistory);

  const [recentSearchListItems, setRecentSearchListItems] = useState<Array<MainLayoutSideMenuItemProps>>([]);

  useEffect(() => {
    setRecentSearchListItems(
      searchHistory.map(
        queryString =>
          ({
            label: queryString,
            link: '/test',
            icon: <Search />,
          } as MainLayoutSideMenuItemProps)
      )
    );
  }, [searchHistory]);

  return (
    <FlexContainer justify="space-between">
      <FlexContainer justify="flex-start" align="flex-start">
        <List>
          {recentSearchListItems.map((props, idx) => (
            <MainLayoutSideMenuItem key={props.label + idx} {...props} />
          ))}
        </List>
        <Divider />
        <List>
          <MainLayoutSideMenuItem label="sideMenu.logOut" link="/logout" icon={<MeetingRoom />} />
        </List>
      </FlexContainer>

      <ProfileMenuSection
        name={userConfig?.name}
        username={userConfig?.username}
        email={user?.email}
        imageId={userConfig?.image_id}
        elevation={4}
        sticky
      />
    </FlexContainer>
  );
};

export default MainLayoutSideMenu;
