import React, { FC } from 'react';
import { Checkbox, Chip, styled, TextField, Typography } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

export interface FilterItemProps {
  category: string;
  filterValues: Array<FilterValue>;
  handleFilterToggle: (option: string, filterGroup: string) => void;
}

export interface FilterValue {
  name: string;
  id: string;
}

const FilterCategoryText = styled(Typography)(({ theme }) => ({
  marginBottom: 3,
  marginTop: 15,
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: 10,
  marginBottom: 5,
  marginTop: 5,
}));

const FilterItem: FC<FilterItemProps> = ({ category, filterValues, handleFilterToggle }) => {
  const { t } = useTranslation('common');
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <>
      <FilterCategoryText>{t('filterListItem.' + category)}</FilterCategoryText>
      {filterValues.length < 10 ? (
        <>
          {filterValues.map(option => (
            <StyledChip key={option.id} label={option.name} onClick={() => handleFilterToggle} variant="outlined" />
          ))}
        </>
      ) : (
        <>
          {' '}
          {filterValues.length > 0 && (
            <Autocomplete
              multiple
              id={category}
              options={filterValues}
              disableCloseOnSelect
              getOptionLabel={option => option.name}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option.name}
                </>
              )}
              style={{ width: '85vw' }}
              renderInput={params => <TextField {...params} variant="standard" />}
            />
          )}
        </>
      )}
    </>
  );
};

export default FilterItem;
