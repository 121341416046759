import { PrimusColors } from '@primus-modules/primus-app-core';
// COLOR-DEFINITIONS
// This is where the actual color should be defined.
// Intended use is only in this file (no 'export')

const PRIMUS_PRIMARY = PrimusColors.PRIMUS_PRIMARY_COLOR;
const PRIMUS_SECONDARY = PrimusColors.PRIMUS_PRIMARY_DARK_COLOR;

// COLOR-REFERENCES
// This is where you define a variable to use in your component.
// This variable should refer to a color above, and should be exported
export const DEFAULT_THEME_PRIMARY_COLOR = PRIMUS_PRIMARY;
export const DEFAULT_THEME_SECONDARY_COLOR = PRIMUS_SECONDARY;
