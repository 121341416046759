import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../Store';
import { Artifact, UserConfig } from '@primus-modules/primus-app-core';
import { PersistentStore } from '../PersistentStore';
import { getFdvApi } from '../../services/FdvApi';

export interface UserState {
  userConfig: UserConfig | null;
  user: Artifact | null;
}

const name = 'user';

export const setUser = createAsyncThunk(`${name}/setUser`, async () => {
  const api = getFdvApi();
  const userConfig = await api.getUserConfig();
  const user = !!userConfig?.artifact_id ? await api.getArtifact(userConfig.artifact_id) : null;
  return { userConfig, user };
});

const userSlice = createSlice({
  name,
  initialState: {
    userConfig: PersistentStore.getS<UserConfig, UserState>(name, 'userConfig'),
    user: PersistentStore.getS<Artifact, UserState>(name, 'user'),
  } as UserState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setUser.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }
      const { userConfig, user } = action.payload;
      state.userConfig = userConfig || state.userConfig;
      state.user = user || state.user;
      PersistentStore.setS<UserState>(name, 'userConfig', state.userConfig);
      PersistentStore.setS<UserState>(name, 'user', state.user);
    });
  },
});

export const selectUserConfig = (state: RootState) => state.user.userConfig;
export const selectUser = (state: RootState) => state.user.user;

export const userReducer = userSlice.reducer;

export default userReducer;
