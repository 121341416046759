import { PrimusApi, SearchParams } from '@primus-modules/primus-app-core';

export class FdvApi extends PrimusApi {
  private static readonly storeKey: string = 'fdvApi/server';

  private baseUrl: string;

  constructor() {
    super(
      process.env.REACT_APP_EKULTUR_GATEWAY_URL || '',
      process.env.REACT_APP_EKULTUR_LOGIN_HOST_URL || '',
      process.env.REACT_APP_EKULTUR_PRIMUS_APP_ID || ''
    );

    this.baseUrl = window.localStorage.getItem(FdvApi.storeKey) || '';
  }

  protected getBaseUrl(): string {
    return this.baseUrl;
  }

  protected getPathPrefix(): string {
    return '/fdv_api/v1.0';
  }

  protected setPrimusInstance(serverUrl: string | null): void {
    this.baseUrl = serverUrl || '';
    window.localStorage.setItem(FdvApi.storeKey, this.baseUrl);
  }

  public getFullApiUrl(): string {
    return this.getBaseUrl() + this.getPathPrefix();
  }

  public async getLastUpdatedObjects(): Promise<Array<any>> {
    const res = await this.search({
      filters: [
        {
          object_type: ['BuildingConditionAssessmentEvent', 'BuildingObservationEvent', 'BuildingTreatmentEvent'],
        },
      ],
      query: '*',
      rows: 1000,
      start: 0,
      sort: 'updated_at desc',
    } as SearchParams);
    return res?.artifacts;
  }

  public async fetchSearchedObjects(searchQuery: string): Promise<Array<any>> {
    const res = await this.search({
      fq:
        'object_type:(building OR' +
        ' BuildingConditionAssessmentEvent OR' +
        ' BuildingObservationEvent OR ' +
        'BuildingTreatmentEvent OR' +
        ' task)',
      query: 'artifact_name_upper:*' + searchQuery.toUpperCase() + '*',
      rows: 1000,
      start: 0,
      sort: 'updated_at desc',
    } as SearchParams);
    return res?.artifacts || [];
  }

  public async fetchFilterValues(): Promise<unknown> {
    const fetchJSONData = async (filters: any, sortBy?: string) => {
      try {
        const response = await fetch(`${this.getFullApiUrl()}/search`, {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            filters: filters,
            sort: sortBy || 'artifact_name asc',
          }),
        });

        if (!response.ok) {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error('Failed to fetch JSON');
        }

        const json = await response.json();
        return json.artifacts;
      } catch (e) {
        // pass
      }
    };

    const fetchCollections = async () =>
      // collections: ct_31
      await fetchJSONData({
        object_type: 'ct_31',
        '-marked': true,
      });
    // FETCH REG. LEVELS
    const fetchRegLevels = async () =>
      // reg. levels: ct_0
      await fetchJSONData({
        object_type: 'ct_0',
        '-marked': true,
        filter_field_id: 'ct_84-3',
      });
    // FETCH PRIORITIES (DEVIATION)
    const fetchPriorities = async () =>
      // priorities: ct_69
      await fetchJSONData({
        object_type: 'ct_69',
        '-marked': true,
      });
    // FETCH BUILDING DEVIATION TYPES
    const fetchBuildingDeviationTypes = async () =>
      // reg. levels: ct_52, filter.field_id=ct_84-3
      await fetchJSONData({
        object_type: 'ct_52',
        filter_field_id: 'ct_84-3',
        '-marked': true,
      });
    // FETCH CAUSE OF EVALUATION / PURPOSE FOR BUILDINGS
    const fetchCauseOfEvaluationTypes = async () =>
      // cause of evaluation types: ct_50, filter.adm_event_type_id: ct_49-13
      await fetchJSONData({
        object_type: 'ct_50',
        'filters.adm_event_type_id': 'ct_49-13',
        '-marked': true,
      });
    // FETCH STATUSES
    const fetchStatuses = async () =>
      // statuses: ct_53
      await fetchJSONData({
        object_type: 'ct_53',
        adm_filter_field_id: 'ct_49-19',
        '-marked': true,
      });
    // FETCH MEASURES
    const fetchMeasures = async () =>
      // measures: ct_89
      await fetchJSONData({
        object_type: 'ct_89',
        '-marked': true,
      });
    const collections = await fetchCollections();
    const regLevels = await fetchRegLevels();
    const priorities = await fetchPriorities();
    const buildingDeviationTypes = await fetchBuildingDeviationTypes();
    const causeOfEvaluationTypes = await fetchCauseOfEvaluationTypes();
    const statuses = await fetchStatuses();
    const measures = await fetchMeasures();
    function getArtifactNameAndID(obj: any) {
      if (obj.authority && obj.authority === 'KulturNav') {
        return { name: `${obj.artifact_name} (K)`, id: obj.artifact_id };
      } else {
        return { name: obj.artifact_name, id: obj.artifact_id };
      }
    }

    function getArtifactNames(arr: []) {
      const output = [];
      if (arr) {
        for (let i = 0, max = arr.length; i < max; i++) {
          output.push(getArtifactNameAndID(arr[i]));
        }
      }

      return output;
    }
    return {
      collections: getArtifactNames(collections),
      registrationLevel: getArtifactNames(regLevels),
      conditionClass: getArtifactNames(measures),
      purpose: getArtifactNames(causeOfEvaluationTypes),
      priority: getArtifactNames(priorities),
      status: getArtifactNames(statuses),
      type: getArtifactNames(buildingDeviationTypes),
      hasMarkings: [
        { id: 'ja', name: 'Ja' },
        { id: 'nei', name: 'Nei' },
      ],
    };
  }
}

let fdvApi: FdvApi;

export const getFdvApi = (): FdvApi => {
  if (!fdvApi) {
    fdvApi = new FdvApi();
  }
  return fdvApi;
};
